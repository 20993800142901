import {BasicObject, SafeAny} from '@/types';
import type {MessageDataPayload} from '../webview/webview';
import {toAllCasinoGame, toGame} from '../game-navigate';
import {
  goTo,
  debounce,
  toSports,
  envConfig,
  hideTabbar,
  showTabbar,
  getDeviceCode,
} from '@utils';
import {order} from '@/utils/magic-box';
import {
  getRechargeCustomers,
  postReport,
  TReportType,
  TSourceType,
} from '@/services/global.service';
import {getGameURL} from '@/pages/home/home.service';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {View} from 'react-native';
import Spin from '@/components/basic/spin';
import theme from '@/style';
import {useWebView} from '../hooks/webview.hooks';
import globalStore from '@/services/global.state';
import {packageId} from '@/config';
import {useFocusEffect, useRoute} from '@react-navigation/native';

const gameMap: {
  [key: string]: string;
} = {
  color: 'Color',
  dice: 'Dice',
  '3Digit': 'Digit',
  quick3d: 'Digit',
  scratch: 'Scratch',
  matka: 'GameSatta',
  kerala: 'GameWebView',
  quickStateLottery: 'MixLottery',
  stateLottery: 'MixLottery',
};

enum EventType {
  PLAY = 'play',
  FOLLOWED = 'followed',
  LOGIN = 'login',
  SUCCESS = 'success',
  FAIL = 'fail',
  BACK = 'back',
  USER_EDITOR = 'userEditor',
  START_GAME = 'startGame',
  ROUTE_CHANGED = 'routeChange',
  TABBA_CHANGED = 'tabBar',
  FOCUS_CHANGED = 'focusChanged',
  UNREAD_CHANGED = 'unreadChanged',
  INPUT_FOCUS = 'input_focus',
}

interface IDataFromChat {
  source: string | 'chat-room';
  payload: MessageDataPayload;
}

const IMWebView = () => {
  const [pageLoading, setPageLoading] = React.useState(true);
  const tokenRef = useRef('');
  const [token, setToken] = useState('');
  const [version, setVersion] = useState(0);
  const isFocus = useRef(true);
  const params = useRoute().params as {[k: string]: string};
  useEffect(() => {
    const sub = globalStore.tokenSubject.subscribe(token => {
      setToken(token || '');
    });
    tokenRef.current = localStorage.getItem('token') || '';
    return () => {
      sub.unsubscribe();
    };
  }, []);
  const getSportURL = debounce((gametype: string) => {
    getGameURL(gametype)
      .then((url: string) => {
        toSports(gametype.split('_')[0].toUpperCase() + ' Sports', url);
      })
      .catch(e => console.error('error', e));
  });

  const playNow = (fromData: SafeAny) => {
    const {gameType, rawData, gameName} = fromData;
    const gameCode = gameMap[gameType];
    localStorage.setItem('PlayNow', `im-${gameCode}`);
    const jumpParams = {} as SafeAny;

    switch (gameType) {
      case 'quick3d':
      case '3Digit': {
        jumpParams.id = rawData?.pickThreeId;
        jumpParams.pickName = gameName;
        jumpParams.pickGameType = gameType === 'quick3d' ? 'quick' : 'normal';
        // jumpParams.cycle = item.cycle;
        break;
      }
      case 'matka': {
        jumpParams.type = 'kerala';
        jumpParams.lotteryName = rawData?.lotteryName;
        break;
      }
      case 'kerala': {
        const rawData2Str = rawData || {};
        jumpParams.id = rawData?.matkaId;
        jumpParams.params = `data=${JSON.stringify(rawData2Str)}`;
        break;
      }
      case 'quickStateLottery':
      case 'stateLottery': {
        jumpParams.lotteryID = rawData?.lotteryID;
        jumpParams.isQuick = gameType === 'quickStateLottery' ? '1' : '0';
        break;
      }
    }
    if (gameType === 'scratch' && window) {
      const curLink = window.location.href.split('?')[0];
      const jumpLink = curLink.replace('webview', 'index/scratch');
      window.location.replace(jumpLink);
    } else if (['quickRace', 'casino', 'live'].includes(gameType)) {
      toGame({
        source: rawData?.source,
        name: rawData?.gameName,
        gameUrl: rawData?.gameUrl,
        id: rawData?.gameId,
        tripartiteUniqueness: rawData?.tripartiteUniqueness,
      });
    } else if (gameType === 'sport') {
      getSportURL(
        (rawData?.source || '').toLowerCase().split(' ')[0] + '_sport',
      );
    } else {
      goTo(gameCode, jumpParams);
    }
  };

  const handleMessage = (
    data: string | BasicObject | IDataFromChat,
    postMessage?: Function,
  ) => {
    if (data === 'pageLoading:show') {
      setPageLoading(true);
      return;
    }
    if (data === 'pageLoading:hide') {
      setPageLoading(false);
      return;
    }
    if (typeof data === 'object' && data.source === 'chat-room') {
      console.log('onimmessage', data);
      localStorage.setItem('from', 'im');
      const {event, data: fromData} = data.payload || {};
      const {gameType, userInviteCode} = fromData || {};
      if (userInviteCode) {
        localStorage.setItem('invitationCode', userInviteCode);
      }
      if (fromData.shareId) {
        localStorage.setItem('shareUserId', fromData.shareId);
      }
      switch (event) {
        case EventType.BACK: {
          const curLink = window.location.href.split('?')[0];
          const jumpLink = curLink.replace('index/chat', 'index/home');
          window.location.replace(jumpLink);
          break;
        }
        case EventType.LOGIN:
          // const curLink = window.location.href.split('?')[0];
          // const jumpLink = curLink.replace('index/chat', 'login?fromPage=chat');
          // window.location.replace(jumpLink);
          goTo('Login', {fromPage: 'chat'});
          break;
        case EventType.PLAY: {
          playNow(fromData);
          break;
        }
        case EventType.FOLLOWED: {
          order(fromData.rawData, gameType)
            .then(() => {
              const user = localStorage.getItem('user') || '{}';
              const userId = JSON.parse(user).userId;
              postReport({
                reportType: TReportType.BETS,
                gameName: fromData?.gameName,
                gameType,
                userId,
                inviteCode: fromData?.userInviteCode,
                gameId: fromData?.gameId,
                issueNo: fromData?.issNo,
                sourceType: TSourceType.IM,
                shareUserId: fromData?.rawData?.userId || fromData?.shareId,
              });
              postMessage &&
                postMessage({
                  source: 'chat-room',
                  payload: {
                    event: EventType.SUCCESS,
                    data: {
                      message: 'success!',
                    },
                  },
                });
            })
            .catch(() => {
              postMessage &&
                postMessage({
                  source: 'chat-room',
                  payload: {
                    event: EventType.FAIL,
                    data: {
                      message: 'failed!',
                    },
                  },
                });
            });
          break;
        }
        case EventType.USER_EDITOR: {
          const curLink = window.location.href.split('?')[0];
          const jumpLink = curLink.replace('index/chat', 'profile');
          window.location.replace(jumpLink);
          break;
        }
        case EventType.START_GAME:
          {
            toAllCasinoGame(fromData?.gameId);
          }
          break;
        case EventType.ROUTE_CHANGED:
          if (!isFocus.current) {
            return;
          }
          if (fromData.route.pathname.includes('/home')) {
            showTabbar();
          } else {
            hideTabbar();
          }
          break;
        case EventType.TABBA_CHANGED:
          if (fromData.visible != null) {
            if (fromData.visible) {
              showTabbar();
            } else {
              hideTabbar();
            }
          }
          break;
        case EventType.UNREAD_CHANGED:
          console.log('fromData', fromData);
          if (fromData?.unRead) {
            localStorage.setItem('imCount', fromData.unRead + '');
          } else {
            localStorage.removeItem('imCount');
          }
          globalStore.chatNumSubject.next(fromData?.unRead || 0);
          break;
        case EventType.INPUT_FOCUS:
          if (fromData.isFocused) {
            document.documentElement.scrollIntoView();
          }
          break;
      }
    }
  };

  const [url, setUrl] = useState('');

  useEffect(() => {
    (async () => {
      const deviceCode = getDeviceCode();
      const getUri = async () => {
        if (params && params.amount) {
          const customers = await getRechargeCustomers();
          // console.log('customers', customers);
          const rechargeCustomers = customers.filter(
            // eslint-disable-next-line eqeqeq
            c => c.imCustomer == '1' && c.imUserStatus == '1',
          );
          return `chat/${
            rechargeCustomers[
              Math.floor(Math.random() * rechargeCustomers.length)
            ].imUserId
          }/0`;
        }
        return 'home';
      };
      // const uri =
      //   params && params.amount && params.customerId
      //     ? `chat/${params.customerId}/0`
      //     : 'home';
      const uri = await getUri();
      let _url = `${
        envConfig.IMURL
      }/#/${uri}?packageId=${packageId}&originToken=${token}&isVisitor=${!token}&deviceCode=${deviceCode}&full=2`;

      if (params) {
        const queryParams = Object.keys(params)
          .filter(key => key !== 'customerId')
          .map(key => `${key}=${params[key]}`)
          .join('&');
        _url += `&${queryParams}`;
      }
      setUrl(_url);
    })();
  }, [token, params]);

  const {render, postMessage} = useWebView({
    originUrl: url,
    onMessage: handleMessage,
    onLoadEnd: () => {
      setPageLoading(false);
    },
  });

  // useEffect(() => {
  //   if (token !== tokenRef.current) {
  //     setVersion(v => v + 1);
  //     tokenRef.current = token;
  //   }
  // }, [token]);

  // useFocusEffect(
  //   useCallback(() => {
  //     if (token !== tokenRef.current) {
  //       setVersion(v => v + 1);
  //       tokenRef.current = token;
  //     }
  //   }, [token]),
  // );

  useEffect(() => {
    if (token !== tokenRef.current) {
      setVersion(v => v + 1);
      tokenRef.current = token;
    }
  }, [token]);

  useFocusEffect(
    useCallback(() => {
      isFocus.current = true;
      console.log('postmessageshow');
      postMessage?.({
        source: 'chat-room',
        payload: {
          event: EventType.FOCUS_CHANGED,
          data: {
            isFocused: true,
          },
        },
      });
      return () => {
        // console.log('postmessagehide');
        showTabbar();
        isFocus.current = false;
        postMessage?.({
          source: 'chat-room',
          payload: {
            event: EventType.FOCUS_CHANGED,
            data: {
              isFocused: false,
            },
          },
        });
      };
    }, [postMessage]),
  );

  return (
    <View key={version} style={[theme.fill.fill, theme.flex.col]}>
      <Spin loading={pageLoading} style={[theme.flex.flex1]}>
        {render}
      </Spin>
    </View>
  );
};

export default IMWebView;
